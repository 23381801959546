import React, { useReducer } from 'react';
import { Alert } from 'reactstrap';

export const ALERT_TYPES = {
  success: 'success',
  info: 'info',
  error: 'danger',
  warning: 'warning',
};

const OPEN_ALERT = 'OPEN_ALERT';
const CLOSE_ALERT = 'CLOSE_ALERT';

const initialState = {
  isOpen: false,
  color: ALERT_TYPES.info,
  message: '',
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case OPEN_ALERT:
      return { ...state, ...payload, isOpen: true };
    case CLOSE_ALERT:
      return { ...state, message: '', color: ALERT_TYPES.info, isOpen: false };
    default:
      return state;
  }
};

const useAlert = (initialValues = {}, canClose = true) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...initialValues,
  });

  const dismissAlert = () => canClose && dispatch({ type: CLOSE_ALERT });
  const showAlert = ({ message, color }) =>
    dispatch({ type: OPEN_ALERT, payload: { message, color } });

  const Component = () => (
    <Alert
      color={state.color}
      isOpen={state.isOpen}
      toggle={canClose ? dismissAlert : undefined}
    >
      {state.message}
    </Alert>
  );

  return {
    showAlert,
    dismissAlert,
    Alert: Component,
    state,
  };
};

export default useAlert;
