import gql from 'graphql-tag';

export default gql`
  mutation sendMessage(
    $name: String!
    $email: String!
    $phone: String
    $message: String!
  ) {
    sendMessage(name: $name, email: $email, phone: $phone, message: $message)
  }
`;
