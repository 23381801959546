import client from '../apollo/client';
import sendMessageMutation from '../queries/sendMessage';

export const sendMessage = variables => {
  return client.mutate({
    variables,
    mutation: sendMessageMutation,
  });
};

export default {
  sendMessage,
};
