import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { Button, UncontrolledTooltip } from 'reactstrap';
import CopyIcon from '../../assets/svgs/copy.svg';

const CopyButton = ({ text }) => {
  const [isTextCopied, dispatch] = useState(false);

  useEffect(() => {
    let timeout;

    if (isTextCopied) {
      timeout = setTimeout(() => {
        dispatch(false);
      }, 1500);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isTextCopied]);

  const handleClick = () => {
    copy(text);
    dispatch(true);
  };

  return isTextCopied ? (
    <small> Copiado </small>
  ) : (
    <>
      <Button
        color="link"
        id="btnCopy"
        onClick={handleClick}
        style={{ padding: 0 }}
      >
        <CopyIcon />
      </Button>
      <UncontrolledTooltip trigger="hover" placement="bottom" target="btnCopy">
        Copie para a área de transferência
      </UncontrolledTooltip>
    </>
  );
};

CopyButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default memo(CopyButton);
