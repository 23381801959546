import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormFeedback } from 'reactstrap';
import { Field as FormikField, getIn } from 'formik';
import MaskedInput from 'react-text-mask';
import Field from '../Field';
import Masks from '../../utils/masks';

const getClassNames = ({ isFieldValid, isFieldInvalid }) =>
  classnames('form-control form-control-lg', {
    'is-valid': isFieldValid,
    'is-invalid': isFieldInvalid,
  });

const PhoneField = ({
  name,
  label,
  placeholder,
  children,
  isValid,
  isInvalid,
}) => (
  <Field label={label}>
    <FormikField
      name={name}
      render={({ field, form }) => {
        const isFormSubmitted = form.submitCound > 0;
        const isTouched = isFormSubmitted || getIn(form.touched, field.name);
        const errors = getIn(form.errors, field.name);
        const isFieldInvalid = isInvalid || (isTouched && errors);
        const isFieldValid = isValid || (isTouched && !isFieldInvalid);

        return (
          <>
            <MaskedInput
              className={getClassNames({ isFieldInvalid, isFieldValid })}
              placeholder={placeholder}
              mask={Masks.phone}
              guide={false}
              {...field}
            />
            {isFieldInvalid && <FormFeedback>{errors}</FormFeedback>}
          </>
        );
      }}
    />
    {children}
  </Field>
);

PhoneField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
};

PhoneField.defaultProps = {
  label: '',
  placeholder: '',
  isValid: false,
  isInvalid: false,
  children: null,
};

export default PhoneField;
