import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormFeedback } from 'reactstrap';
import { Field as FormikField, getIn } from 'formik';
import Field from '../Field';

const TextField = ({
  name,
  label,
  placeholder,
  children,
  isValid,
  isInvalid,
  maxLength,
  rows,
  type,
}) => {
  const getClassNames = ({ isFieldValid, isFieldInvalid }) =>
    classnames('form-control form-control-lg', {
      'is-valid': isFieldValid,
      'is-invalid': isFieldInvalid,
    });

  const getClassNamesCheckbox = ({ isFieldValid, isFieldInvalid }) =>
    classnames('', {
      'is-valid': isFieldValid,
      'is-invalid': isFieldInvalid,
    });

  return (
    <Field label={type !== 'checkbox' && label}>
      <FormikField
        name={name}
        render={({ field, form }) => {
          const isFormSubmitted = form.submitCount > 0;
          const isTouched = isFormSubmitted || getIn(form.touched, field.name);
          const errors = getIn(form.errors, field.name);
          const isFieldInvalid = isInvalid || (isTouched && errors);
          const isFieldValid = isValid || (isTouched && !isFieldInvalid);

          return (
            <>
              {type === 'textarea' ? (
                <textarea
                  className={getClassNames({ isFieldInvalid, isFieldValid })}
                  placeholder={placeholder}
                  rows={rows}
                  {...field}
                />
              ) : (
                <>
                  {type === 'checkbox' ? (
                    <label
                      htmlFor="checkbox"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                      }}
                      className={getClassNamesCheckbox({
                        isFieldInvalid,
                        isFieldValid,
                      })}
                    >
                      <input
                        id="checkbox"
                        type="checkbox"
                        style={{
                          width: 15,
                          height: 15,
                          marginRight: 8,
                        }}
                        {...field}
                      />
                      {label}
                    </label>
                  ) : (
                    <input
                      type={type}
                      className={getClassNames({
                        isFieldInvalid,
                        isFieldValid,
                      })}
                      placeholder={placeholder}
                      maxLength={maxLength}
                      {...field}
                    />
                  )}
                </>
              )}
              {isFieldInvalid && <FormFeedback>{errors}</FormFeedback>}
            </>
          );
        }}
      />
      {children}
    </Field>
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  rows: PropTypes.string,
  maxLength: PropTypes.number,
  type: PropTypes.string,
};

TextField.defaultProps = {
  label: '',
  placeholder: '',
  isValid: false,
  isInvalid: false,
  children: null,
  rows: null,
  maxLength: null,
  type: 'text',
};

export default TextField;
