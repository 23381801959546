import React, { useState, useMemo } from 'react';
import { Formik, Form } from 'formik';
import { Button, Col, Row, FormGroup, Spinner } from 'reactstrap';
import Yup from '../../utils/yup';
import EmailField from '../EmailField';
import PhoneField from '../PhoneField';
import TextField from '../TextField';
import { sendMessage } from '../../api/contact';
import useAlert, { ALERT_TYPES } from '../../hooks/useAlert';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  email: Yup.string()
    .matches(/\S+@\w+\.\w+/, 'Informe um email válido')
    .required('Campo obrigatório'),
  phone: Yup.string()
    .matches(/\(\d{2}\) \d{4,5}-\d{4}/, 'Informe um telefone válido')
    .required('Campo obrigatório'),
  message: Yup.string().required('Campo obrigatório'),
});

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const {
    showAlert,
    dismissAlert,
    Alert,
    state: { color: alertColor },
  } = useAlert();

  const showSubmit = useMemo(() => alertColor !== ALERT_TYPES.success, [
    alertColor,
  ]);

  const onSubmit = async (values, { resetForm }) => {
    setLoading(true);
    dismissAlert();
    try {
      await sendMessage(values);
      showAlert({
        color: ALERT_TYPES.success,
        message: 'Mensagem enviada com sucesso.',
      });
      resetForm();
    } catch (e) {
      showAlert({ color: ALERT_TYPES.error, message: e.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        phone: '',
        message: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={() => (
        <Form>
          <TextField name="name" label="Nome" />
          <Row form>
            <Col xs="12" md="6">
              <EmailField name="email" label="E-mail" />
            </Col>
            <Col xs="12" md="6">
              <PhoneField name="phone" label="Telefone" />
            </Col>
          </Row>
          <TextField
            type="textarea"
            name="message"
            label="Escreva sua mensagem"
          />
          {showSubmit && (
            <FormGroup>
              <Button type="submit" disabled={loading}>
                {loading && <Spinner size="sm" color="light" />}
                Enviar
              </Button>
            </FormGroup>
          )}
          <Alert />
        </Form>
      )}
    />
  );
};

export default ContactForm;
