import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormFeedback, Input } from 'reactstrap';
import { Field as FormikField, getIn } from 'formik';
import Field from '../Field';

const EmailField = ({
  name,
  label,
  placeholder,
  children,
  isValid,
  isInvalid,
}) => {
  const getClassNames = ({ isFieldValid, isFieldInvalid }) =>
    classnames('form-control form-control-lg', {
      'is-valid': isFieldValid,
      'is-invalid': isFieldInvalid,
    });

  return (
    <Field label={label}>
      <FormikField
        name={name}
        render={({ field, form }) => {
          const isFormSubmitted = form.submitCound > 0;
          const isTouched = isFormSubmitted || getIn(form.touched, field.name);
          const errors = getIn(form.errors, field.name);
          const isFieldInvalid = isInvalid || (isTouched && errors);
          const isFieldValid = isValid || (isTouched && !isFieldInvalid);

          return (
            <>
              <Input
                type="email"
                placeholder={placeholder}
                className={getClassNames({ isFieldInvalid, isFieldValid })}
                {...field}
              />
              {isFieldInvalid && <FormFeedback>{errors}</FormFeedback>}
            </>
          );
        }}
      />
      {children}
    </Field>
  );
};

EmailField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
};

EmailField.defaultProps = {
  label: '',
  placeholder: '',
  isValid: false,
  isInvalid: false,
  children: null,
};

export default EmailField;
